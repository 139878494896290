.NavigationItem {
    transition: var(--transition);
    margin: 0 1.5rem;
    text-decoration: none;
    color: rgb(255, 255, 255);
    opacity: 0.5;
    position: relative;
    font-weight: 500;
}
.NavigationItem:hover , .ActiveNavigationItem , .ActiveNavigationItem:hover {
    opacity: 1;

    cursor: pointer;
}



.NavigationItem::before {
    transition: var(--transition);
    position: absolute;
    content: "";
    width: 1rem;
    height: 2px;
    background-color: white;
    bottom: -.25rem;
    left: calc(50% - 0.5rem);
    opacity: 0;
    border-radius: 50px;
}

.ActiveNavigationItem.NavigationItem::before {
    opacity: 1;
}