

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 200;
	src: url('./fonts/woff/Morabba-UltraLight.woff') format('woff'),   
	url('./fonts/woff2/Morabba-UltraLight.woff2') format('woff2');	
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/woff/Morabba-Light.woff') format('woff'),   
	url('./fonts/woff2/Morabba-Light.woff2') format('woff2');		 
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 500;
	src: url('./fonts/woff/Morabba-Medium.woff') format('woff'),   
	url('./fonts/woff2/Morabba-Medium.woff2') format('woff2');		 
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 600;
	src: url('./fonts/woff/Morabba-SemiBold.woff') format('woff'),   
	url('./fonts/woff2/Morabba-SemiBold.woff2') format('woff2');	 
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 800;
	src: url('./fonts/woff/Morabba-ExtraBold.woff') format('woff'),   
	url('./fonts/woff2/Morabba-ExtraBold.woff2') format('woff2');		 
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 850;
	src: url('./fonts/woff/Morabba-Black.woff') format('woff'),   
	url('./fonts/woff2/Morabba-Black.woff2') format('woff2');		 
}


@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: 900;
	src: url('fonts/woff/Morabba-Heavy.woff') format('woff'),   
	url('fonts/woff2/Morabba-Heavy.woff2') format('woff2');		 
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/woff/Morabba-Bold.woff') format('woff'),   
	url('./fonts/woff2/Morabba-Bold.woff2') format('woff2');	 
}

@font-face {
	font-family: Morabba;
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/woff/Morabba-Regular.woff') format('woff'),   
	url('./fonts/woff2/Morabba-Regular.woff2') format('woff2');	
}


