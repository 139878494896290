.Event {
    position: relative;
    top: 0;
    height: calc(50vh);
    width: calc(50vw - 6rem);
    min-width: 15rem;
    background-color: rgba(243, 243, 243, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 10px;
    margin: 0.5rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: grayscale(1);
    transition: all 0.3s ease;
    padding: 2rem;
 
}
.Event:hover {
    filter: grayscale(0);
    cursor: pointer;
}
.Event.Active {
    height: 60vh;
    width: 100vw;
    filter: grayscale(0);
    top: -8.5rem;
    right: -5rem;
    align-items: flex-start;
    border-radius: 0;
    
}
.Event.Active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
}
.EventContent {
    position: relative;
    top: -8.5rem;
}
.Name {
    margin: 0;
    font-size: 3rem;
    filter: drop-shadow(0px 0px 10px black);
}
.Detail {
    color: rgb(201, 201, 201);
    margin: 0.5rem;
}
.Description {
    color: rgba(255, 255, 255, 0.75);
}
.AllEventsButton {
    position: absolute;
    bottom: 0;
    right: calc(100% - 6rem);
    
}

.Sponsors {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    /* flex-direction: column; */
    flex-wrap: wrap;
}

@media screen and (max-width : 600px) { 
    .Event:not(.Active)  , .EventContainer:not(.Active) {
        width: 100% !important;
        margin: 0.25rem 0;
    }
    .Event.Active {
       
        right: -3rem;
      
    }
    
}