.HamMenu {
  z-index: 50000;
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.HamMenuList {
  position: fixed;
  right: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  height: 100%;
  width: 100vw;
  padding: 1.5rem;
  z-index: 50000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
  transform: translateX(100vw);
  opacity: 0;
}
.HamMenuList.ShowList {
  transform: translateX(0);
  opacity: 1;
}
.HamMenuButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
  z-index: 50001;
  position: relative;
}
.HamMenuButton div {
  width: 24px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
  border-radius: 100px;
}
.FirstLine,
.ThirdLine {
  transition: var(--transition);
  transition-property: transform;
}
.CloseButton {
  position: fixed;
  right: 0.9rem;
  top: 1.96rem;
}
.CloseButton .FirstLine {
  transform: rotate(45deg) translateY(8.5px);
  position: relative;
}
.CloseButton .SecondLine {
  opacity: 0;
}
.CloseButton .ThirdLine {
  transform: rotate(-45deg) translateY(-8.5px);
  position: relative;
}
@media screen and (max-width: 750px) {
  .HamMenu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
