.SpeakerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
}

.Speaker {
}
.Video {
    width: calc(100% - 18vw - 5rem);
    height: calc(calc(calc(100vw - 18vw - 17rem) / 16) * 9);
    border: none;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
.Image {
    width: 18vw;
    border-radius: 5px;

}
.Name , .Detail  {
    margin: 0;
    margin-top: 0.25rem;
    width: 18rem;
    
}
.Name {
    display: flex ;
    align-items: center;
    justify-content: space-between;
}
.DetailsList  {
    padding-left: 1rem;
    list-style-type: square;
}
.Detail {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0.5rem;
}
.SocialMediaContainer {
    display: flex;
    align-items: center;
}
.SocialMediaIcon {

}
.SocialMediaIcon svg{
    fill: rgba(0, 0, 0, 0.5);
    width: 1rem;
    transition: var(--transition);


}
.SocialMediaIcon:hover svg {
    fill: black;
}
@media screen and (max-width : 600px) {
    .NameAndImage {
        display: flex;
    }
    .Name {
        margin-right: 1rem;
    }
    .SpeakerContainer  {
        flex-direction: column;
        align-items: flex-start;
    }
    .Video {
        width: 100%;
        height: calc((100vw - 2rem)/ 16 * 9);

    }
}