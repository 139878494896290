.Section {
    padding: 1rem;
    min-height: 100vh;
    overflow-x: hidden;
  }
  .Section .Content {
    padding: 2rem 3rem;
    animation: onLoad 0.5s ease 0s 1;
    height: 100%;
  }
  .Section .Content {
    margin-top: 5rem;
  }
  .EventsContainer {
    display: flex;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.3s ease;
  }
  @media screen and (max-width : 600px) {
    .Section .Content {
      padding: 1rem;
    }
  }