.About {
  z-index: 5;
}
.Section {
  padding: 1rem;
  min-height: 100vh;
}
.Section.Top {
  background-color: black;

}
.Section .Content {
  padding: 5rem;

}
.Section.Top .Content {
  margin-top: 5rem;
}
.Section .Content h1 {
    color: white;
}

.Section .Content p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}
@media screen and (max-width : 600px) {
  .Section .Content {
    padding: 2rem;
  }
}
