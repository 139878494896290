.Home {}

.Section {
  padding: 1rem;
  min-height: 100vh;
}

.Section.Intro {
  background-color: black;
  display: flex;
  align-items: center;
  padding: 0rem;
  height: 100%;
}

.Section .Content {
  padding: 2rem 3rem;
  animation: onLoad 0.5s ease 0s 1;
  height: 100%;
}

.Section.Intro .Content {
  margin-top: 5rem;
  padding: 0;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  grid-template-columns: 40% 60%;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh - 1rem);
}

.Section .Content h1 {
  position: relative;
  z-index: 1;
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.Section.Intro .Content h1 {
  color: rgb(255, 255, 255);
  font-size: 3.5rem;
  /* font-weight: 500; */
  margin: 0;
}
.Section.Intro .Content h2 {
  font-size: 1.75rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.75);

}
.Section.Intro .Content .Details {
  position: relative;
  z-index: 2;
  color: black;
  padding: 2.5rem;
  /* border-radius: 10px; */
  height: 100%;
  width: 100%;
  max-width: 640px;
}

.Highlight {
  color: rgb(0, 153, 255);
  font-weight: bolder;
  
}


.Details p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.125rem;
}

.Section.Intro {
  background-image: 
  linear-gradient(to bottom, transparent , rgb(0, 0, 0) 99.9%),
  linear-gradient(to right, rgb(22,33,55)  , rgb(9, 14, 23));
    /* url(./img/background.jpg); */

  background-position: left;
  background-size: auto 100%;
  background-repeat: no-repeat;
  /* animation: backgroundFlash 10s ease 0s infinite alternate; */
}
.SpeakersContainer {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-items: center;
  align-content: center;
}

.Stars {
  position: absolute;
  left: 5px;
  top :5px;
  z-index: 2;
}
.Star {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 10px 2px white;
  animation: StarBlink 2s ease 0s infinite alternate;
  opacity: 0.3;
}
.About .Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.About .Details h1 {
  /* font-size: 2.5rem; */
}
.AboutVideoContainer {
  height: calc(calc(55vw / 16) * 9);
  width: 100%;
  margin-left: 3rem;
}
.AboutVideo {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
}
@keyframes StarBlink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
.ModalContainer {
 position: fixed;
 left: 0;
 top: 0;
 z-index: 500;
 width: 100vw;
 height: 100vh;
 background-color: rgba(0, 0, 0, 0.5);
 display: flex;
 align-items: center;
 justify-content: center;
}
.Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(15, 15, 15);
  padding: 2rem;
  border-radius: 10px;
  width: 32rem;
  text-align: center;
}
.ModalCTA {
  margin-top: 2rem;
}
.Modal a {
  text-decoration: none;
}
@keyframes backgroundFlash {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@media screen and (max-width : 900px) {
  .Section.Intro .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    
  }
  .Earth {
    position: relative;
    top: -4rem;

    /* transform: scale(0.75); */
  }
  .Section.Intro .Content .Details {
    position: absolute;
    top: calc(50vh + 5rem);
    padding: 1rem;
    height: 50vh;
    z-index: 4;
  }
  .Section.Intro .Content h1 {
    width: 100%;
    min-width: unset;
    font-size: 1.5rem;
  }
  .Section.Intro .Content h2 {
    width: 100%;
    min-width: unset;
    font-size: 1rem;
  }
  .About .Content {
    flex-direction: column;
  }
  .AboutVideoContainer {
    width: 100%;
    height: calc(calc(90vw / 16) * 9);

    margin-left: 0;
  }
}
.Help {
  position: relative;
  top: 3rem;
  right: 1rem;
  display: flex;
  z-index: 50;

}
.Help .Text {
  font-weight: normal;
  width: 7rem;
}
.Earth {
  /* filter: contrast(1.1); */
  position: relative;
  top: -5vh;
  z-index: 3;
}

@media screen and (max-width : 600px) {
  .Help {
    /* flex-direction: column; */
    /* align-items: center; */
    padding-right: 2rem;
    /* top: 3rem; */
    z-index: 50;
  }
  .Section .Content {
    padding: 1rem;
  }
}
@keyframes onLoad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}