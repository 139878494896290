.Personalities {
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    min-height: calc(100vh);
    position: relative;
    overflow-x: hidden;
}
.Description {
    margin: 2rem 0;
    border-radius: 10px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.15);
}
.NextButton {
    margin: 1rem 0;
    width: 100%;
    
}
.NextContainer {
   position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
    background-color: black;
    width: 100%;
}
