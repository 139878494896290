.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    width: 100%;
    z-index: 5;
    padding: 1rem 5.5rem;
    background-color: black;
    background: linear-gradient(to bottom , rgb(0, 0, 0) , rgb(30, 30, 30) );
    color: white;
    position: relative;
    z-index: 10;
}
@media screen and (max-width : 750px) {
    .Footer {
        padding: 1rem;

    }
}