.GoUpButton {
    border: none;
    border-radius: 5px;
    width: 2.5rem;
    height: 2.5rem;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    background-color: rgb(75, 75, 75);
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    transform: translateY(5rem);
    opacity: 0;

}
.GoUpButton.Show {
    transform: translateY(0);
    opacity: 1;

}
.Arrows {
    position: relative;
    right: 1rem;
}
.LeftArrow , .RightArrow{
    width: 1rem;
    height: 2px;
    background-color: rgb(255, 255, 255);
    position: relative;
    border-radius: 200px;

}
.LeftArrow {
    transform: rotateZ(-45deg);
    left: 0.3rem;

}
.RightArrow {
    transform: rotateZ(45deg);
    left: 0.95rem;
    bottom: 0.1rem;
}
.GoUpButton:hover {
    background-color: rgb(225, 225, 225);

}
.GoUpButton:hover .LeftArrow ,.GoUpButton:hover  .RightArrow {
    background-color: rgba(0, 0, 0, 0.75);
}