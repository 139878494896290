:root {
  --transition : all 0.3s ease;
  --primary-color : rgba(0, 158, 226, 1);
  --border-radius : 10px;
}
* {
  box-sizing: border-box;
  color: white;

}
a {
  text-decoration: none
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url(./Morabba/fontiran.css);
body {
  margin: 0;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}
body , button {
  font-family: Morabba , 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(52, 52, 52);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(69, 69, 69);
}
.skeleton-box {
  /* display: inline-block; */
  /* height: 1em; */
  position: relative;
  overflow: hidden;
  /* background-color: #dddbdd; */
  border-radius: var(--border-radius);
  
}

.skeleton-box::after {
  position: absolute;
  content: "";
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,rgba(255, 255, 255 , 0) 0,
  rgba(255, 255, 255 , 0.2) 20%,rgba(255, 255, 255 , 0.5) 60%,rgba(255, 255, 255 , 0));
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}