/* .TeamMemebers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */
.TeamMemebers {
    margin: 1rem 0;
}
.TeamMemebersContainer {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    justify-content: center;
    justify-items: center;
}
.Title {
    color: white;
    font-size: 1.5rem;
    text-align: center;
}