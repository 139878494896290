.Contact {}

.Section {
  padding: 1rem;
  height: 100vh;
}

.Section.Top {
  background-color: black;

}

.Section .Content {
  margin-top: 5rem;
  padding: 5rem;
}

.Section .Content h1 {
  color: white;
}

.Section .Content p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

.ContactWayTitle {
  color: white;
  font-size: 1.25rem;
}

.ContactWay {
  margin: 1rem 0;
}

.SocialMedia,
.ContactWay {
  display: flex;
  align-items: baseline;
}

.ContactWay svg {
  fill: white;
  height: 1rem;
  width: 1rem;
}
.SocialMedia .ContactWay svg {
  width: 1.5rem;
  height: 1.5rem;
}
.SocialMedia .ContactWay {
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}
.ContactWay div {
  color: rgba(255, 255, 255, 0.75);
  margin: 0 0.5rem;
  word-wrap: break-word;
  max-width: calc(100% - 4rem);
}

@media screen and (max-width : 600px) {
  .Section .Content {
    padding: 2rem;
  }
}