.CountdownRenderer {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    text-decoration: none;
    margin: 1rem 0;
}
.Days {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
.Highlight {
    color: var(--primary-color);
}