.Sponsor {
    margin: 0.5rem 1rem;
    display: flex;
    /* flex-wrap: wrap; */
    /* align-items: center; */
    /* flex-direction: column; */
}
.Sponsor img {
    border-radius: 10px;
    background-color: white;
    margin-left: 1rem;
}
.Details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Name {
    margin: 0.5rem;
    
}
.Detail {
    margin: 0.25rem;
    opacity: 0.5;
    font-size: 0.8rem;
   
}
.Description {
    opacity: 0.75;
    font-size: 1rem;
    /* min-width: 10rem; */
    text-align: justify;
}
.Modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 5000;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 10%;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter :  blur(6px);
    overflow: auto;
}
.Modal .SponsorDetails {
    flex-direction: row;
    display: flex;
    align-items: center;
}
.AboutBtn{
    
    width: 10rem;
}