.Notfound {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5rem 2rem;
    text-align: center;

}
.Notfound img{
    width: 32rem;
    max-width: 100%;
}
.Notfound a {
    text-decoration: none;
}
