.TeamMemeber {
    /* margin: 0.5rem; */
    animation: onLoad 0.5s ease 0s 1;
    opacity: 1;
    border: none;
    position: relative;
    z-index:2;
    transition: var(--transition);
    /* border: 1px solid rgba(139, 139, 139, 0.5); */
}
.ImageContainer {
    transition: var(--transition);

    position: relative;
    overflow: hidden;
    /* border-radius: 5px; */
    width: 12.5rem;
    height: 12.5rem;
    border: none;
    background-color: rgba(255, 255, 255, 0.05);
    filter: grayscale(1);
}
.TeamMemeber:hover .ImageContainer  {
    filter: grayscale(0);

}
.SocialMedia {
  
    padding: 0.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  
}
.Details {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.25)  , rgba(0, 0, 0, 0.75));
    transform: translateY(3rem); 
    transition: var(--transition);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0.5rem;
}
.TeamMemeber:hover .Details {
    transform: translateY(0);
    opacity: 1;

}
.Image {
    width: 100%;
    height: 100%;
    border: none;

}
.Name , .Position {
    max-width: 12.5rem;
    margin: 0.25rem;

}
.Name {
    color: white;
    font-size: 1rem;
}
.SocialMediaIcon svg {
    width: 1rem;
    height: 1rem;
}
.TeamMemeber p.Position {
    font-size: 0.75rem !important;

}

@keyframes onLoad {
    0% {
      opacity: 0;
      filter: grayscale(1);
    }
    100% {
      opacity: 1;
      filter: grayscale(0);

    }
  }