.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5;
    padding: 1rem 5.5rem;
    background: linear-gradient(to top, rgb(0, 0, 0 ,0) , rgb(50, 50, 50));;
}
@media screen and (max-width : 900px) {
    .Header {
        padding: 1rem;

    }
}