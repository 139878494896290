.BlurCircle {
  border-radius: 50%;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  -moz-filter: blur(100px);
  -o-filter: blur(100px);
  -ms-filter: blur(100px);
  position: absolute;
  z-index: 0;
  animation: onLoad 0.5s ease 0s 1;

}

@keyframes onLoad {
  0% {
    opacity: 0;
    -moz-opacity:0;
  }

  100% {
    opacity: 0.25;
    -moz-opacity:0.725;
  }
}